import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    // redirect: '/wall-3d'
    //component: () => import(/* webpackChunkName: "wall" */ "../views/wall/wall.vue")
    //component: () => import(/* webpackChunkName: "controller" */ "../views/ipad/controller.vue")
    component: () => import(/* webpackChunkName: "lottery-3d" */ "../views/lottery/lottery.vue")
  },
  {
    path: "/lottery",
    name: "lottery-3d",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lottery-3d" */ "../views/lottery/lottery.vue")
  }
  ,{
    path: "/wall",
    name: "wall-3d",
    component: () => import(/* webpackChunkName: "wall-3d" */ "../views/wall/wall.vue")
  },{
    path: "/controller",
    name: "controller",
    component: () => import(/* webpackChunkName: "controller" */ "../views/ipad/controller.vue")
  },{
    path: "/terminus",
    name: "terminus",
    component: () => import(/* webpackChunkName: "controller" */ "../views/ipad/terminus.vue")
  },{
    path: "/player",
    name: "player",
    component: () => import(/* webpackChunkName: "player" */ "../views/ipad/player.vue")
  }
]

const router = new VueRouter({
  //mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
